<template>
  <div>
    <b-modal  v-model="mode" centered 
         no-close-on-esc
         no-close-on-backdrop 
         hide-header-close 
         hide-footer size="lg" >
        
        <form  @submit.prevent="submitHandler">
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3">
                <label class="form-label" for="inclusion"
                  >Title</label
                >
                <input
                  type="text"
                  v-model="state.title"
                  class="form-control"
                  @blur="v$.title.$touch()"
                  :class="v$.title.$errors.length > 0 || error ? 'is-invalid' : ''"
                  placeholder="Enter Title"
                  id="inclusion"
                />
                <div class="invalid-feedback"  v-for="error of v$.title.$errors" :key="error.$uid">{{`Title ${error.$message}`}}</div>  
                <div class="invalid-feedback"  v-for="(err,i) in error" :key="i" >{{`${err}`}}</div>  
              </div>
            </div>
           
            <div class="col-md-6">
              <div class="mb-3">
                <label class="form-label" for="inclusion"
                  >Plan Type</label
                >
                <select v-model="state.type"
                 @blur="v$.type.$touch()"
                  :class="v$.type.$errors.length > 0 || error ? 'is-invalid' : ''"
                  class="form-select">
                    <option value="" selected>Select Plan Type</option>
                    <option value="free">Free</option>
                    <option value="premium">Premium</option>
                 
                </select>
                <div class="invalid-feedback"  v-for="error of v$.type.$errors" :key="error.$uid">{{`Plan Type ${error.$message}`}}</div>  
                <div class="invalid-feedback"  v-for="(err,i) in error" :key="i" >{{`${err}`}}</div>  
              </div>
            </div>  
            <div class="col-md-6">
              <div class="mb-3">
                <label class="form-label" for="inclusion"
                  >Terms</label
                >
                <select v-model="state.terms"
                 @blur="v$.terms.$touch()"
                  :class="v$.terms.$errors.length > 0 || error ? 'is-invalid' : ''"
                  class="form-select">
                    <option value="" selected>Select Terms</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="6 months">6 Months</option>
                    <option value="yearly">Yearly</option>
                    <option value="lifetime">Lifetime</option>
                </select>
                <div class="invalid-feedback"  v-for="error of v$.terms.$errors" :key="error.$uid">{{`Terms ${error.$message}`}}</div>  
                <div class="invalid-feedback"  v-for="(err,i) in error" :key="i" >{{`${err}`}}</div>  
              </div>
            </div>  
            <div class="col-md-6">
              <div class="mb-3">
                <label class="form-label" for="inclusion"
                  >Price</label
                >
                <input
                  type="number"
                  v-model="state.price"
                  class="form-control"
                  @blur="v$.price.$touch()"
                  :class="v$.price.$errors.length > 0 || error ? 'is-invalid' : ''"
                  placeholder="Enter Price"
                  id="inclusion"
                />
                <div class="invalid-feedback"  v-for="error of v$.price.$errors" :key="error.$uid">{{`Price ${error.$message}`}}</div>  
                <div class="invalid-feedback"  v-for="(err,i) in error" :key="i" >{{`${err}`}}</div>  
              </div>
            </div>  
            <div class="col-md-12">
              <div class="mb-3">
                <label class="form-label" for="inclusion"
                  >Inclusion</label
                >
                <v-select    
                    class="form-select"
                    placeholder="Select Inclusion"
                    @blur="v$.inclusion.$touch()"
                    :class="v$.inclusion.$errors.length > 0 || error ? 'is-invalid' : ''" 
                    taggable
                    multiple
                    v-model="state.inclusion" 
                    :options="inclusion" 
                    label="title" 

                  >
                  </v-select>
                <div class="invalid-feedback"  v-for="error of v$.inclusion.$errors" :key="error.$uid">{{`Inclusion ${error.$message}`}}</div>  
                <div class="invalid-feedback"  v-for="(err,i) in error" :key="i" >{{`${err}`}}</div>  
              </div>
            </div> 
            <div class="col-md-8">
              <div class="mb-3">
                <label class="form-label" for="inclusion"
                  >Days to notify before expiration</label
                >
                <input
                  type="number"
                  v-model="state.day_notification"
                  
                  class="form-control"
                  @blur="v$.day_notification.$touch()"
                  :class="v$.day_notification.$errors.length > 0 || error ? 'is-invalid' : ''"
                  placeholder="Number of Days"
                  id="inclusion"
                />
                <div class="invalid-feedback"  v-for="error of v$.day_notification.$errors" :key="error.$uid">{{`Days to notify ${error.$message}`}}</div>  
                <div class="invalid-feedback"  v-for="(err,i) in error" :key="i" >{{`${err}`}}</div>  
              </div>
            </div> 
              <div class="col-md-2">           
                <div class="mb-3 mt-4">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      value="true"
                      v-model="state.featured"
                    />
                    <label class="form-check-label" for="gridCheck">
                      Featured
                    </label>
                  </div>
                </div>
              
              </div>
              
              <div class="col-md-12">
              <div class="mb-3">
                <label class="form-label" for="inclusion"
                  >Meta Keyword</label
                >
                <input
                  type="text"
                  v-model="state.meta_keyword"
                  class="form-control"
                  @blur="v$.meta_keyword.$touch()"
                  :class="v$.meta_keyword.$errors.length > 0 || error ? 'is-invalid' : ''"
                  placeholder="Enter Title"
                  id="inclusion"
                />
                <div class="invalid-feedback"  v-for="error of v$.meta_keyword.$errors" :key="error.$uid">{{`Meta Keyword ${error.$message}`}}</div>  
                <div class="invalid-feedback"  v-for="(err,i) in error" :key="i" >{{`${err}`}}</div>  
              </div>
              <div class="col-md-12">
              <div class="mb-3">
                <label class="form-label" for="inclusion"
                  >Meta Description</label
                >
                <textarea
                 v-model="state.meta_description" 
                 @blur="v$.meta_description.$touch()"
                  :class="v$.meta_description.$errors.length > 0 || error ? 'is-invalid' : ''"
                 class="form-control">
                </textarea>
                <div class="invalid-feedback"  v-for="error of v$.meta_description.$errors" :key="error.$uid">{{`Meta Description ${error.$message}`}}</div>  
                <div class="invalid-feedback"  v-for="(err,i) in error" :key="i" >{{`${err}`}}</div>  
              </div>
            </div>
              </div>
              <div class="col-12 text-end">
                <button
                  type="button"
                  class="btn btn-danger me-1"
                  @click="closeMode"
                >
                  <i class="bx bx-x me-1"></i> Cancel
                </button>
               
                <button-spinner 
                    className='btn btn-success' 
                    typeName="submit" 
                    :loading="spinner ? true : false" 
                    :disableValue="spinner ? true : false" 
                    buttonName="Submit"/>
              </div>
            </div>
        </form>
    </b-modal>


</div>
</template>
<script>
import {computed,reactive,onMounted} from 'vue';
import useVuelidate from '@vuelidate/core'
import { required,helpers } from '@vuelidate/validators'
import { useStore } from 'vuex'
import buttonSpinner from '../../../../components/utility/button-spinner'
import "vue-select/dist/vue-select.css";
import vSelect from 'vue-select'

export default {
props:{
  modalShow:{
    type:Boolean,
    default:false
  }
},
components:{
  buttonSpinner,
  vSelect
},  
setup(props,{ emit }) {
  const store = useStore();
  const mode = computed(()=>{
      return  props.modalShow
  })
  const closeMode = () =>{
      emit('modalClose')
  }
  const state = reactive({
    title:'',
    price:'',
    terms:null,
    inclusion:null,
    day_notification:null,
    featured:false,
    type:'',
    meta_keyword:'',
    meta_description:''
  })
  const rules = {
    title: { required: helpers.withMessage('field cannot be empty', required)},
    type: { required: helpers.withMessage('field cannot be empty', required)},
    price: { required: helpers.withMessage('field cannot be empty', required)},
    terms: { required: helpers.withMessage('field cannot be empty', required)},
    inclusion: { required: helpers.withMessage('field cannot be empty', required)},
    day_notification: { required: helpers.withMessage('field cannot be empty', required)},
    meta_keyword: { required: helpers.withMessage('field cannot be empty', required)},
    meta_description: { required: helpers.withMessage('field cannot be empty', required)}
  }
  const v$ = useVuelidate(rules, state)
  const  inclusion =  computed(() =>{ return  store.getters['subscription/getInclusionList'] || [] })
  const  spinner =  computed(() =>{ return  store.getters['subscription/processingData']})
  const  error =  computed(() =>{ return  store.getters['subscription/error']})

  async function submitHandler(){
    const result =await v$.value.$validate()
    if (!result) {
      return
    }else{
      await store.dispatch('subscription/addPlan', state)
      if(error.value === false) {
          emit('modalClose')
      }
      store.dispatch('subscription/getPlan',{page:1,search:''});

    }
  }
  onMounted(()=>{
      store.dispatch('subscription/getInclusionList');
  })
  return {
    spinner,
    inclusion,
    error,
    mode,
    closeMode,
    state, 
    v$,
    submitHandler
  }
}
}
</script>

<style>

</style>