<template>
  <Layout :pagetitle="'Subscription Plan'">
    <div class="row">
      <div class="col-12">
        <div class="position-relative">
              <div class=" modal-button mt-2">
                <b-button
                  type="button"
                  class="btn btn-success"
                  size="md"
                  @click="showModalCreate"
                >
                   Add New Plan
                   <i class="mdi mdi-plus me-1"></i>
                </b-button>
              </div>
        </div>
      </div>
      
      <div id="table-ecommerce-shops">
              <div role="complementary" class="gridjs gridjs-container">
                <div class="gridjs-head">
                  <div class="gridjs-search">
                    <input 
                      placeholder="Type a keyword..." 
                      aria-label="Type a keyword..."  
                      class="gridjs-input gridjs-search-input" 
                      v-model = "search"
                      type="text"
                      />
                  </div>
                </div>
                <div class="gridjs-wrapper" style="height: auto">
                <div v-if="success != false"
                    class="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                  {{`Plan ${success}`}}
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                      @click="unSubscribeSuccess"
                ></button>
                </div>
                <div class="gridjs-wrapper" style="height: auto">
                  <table role="grid" class="gridjs-table table table-hover" style="height: auto;width: 100%;">
                    <thead class="gridjs-thead">
                      <tr class="gridjs-tr">
                        <!-- <th
                          data-column-id="brand"
                          class="gridjs-th gridjs-th-sort"
                          tabIndex="0"
                          style="minwidth: 78px; width: 40px"
                        >
                          <div class="gridjs-th-content">Id</div>
                        </th> -->
                        <th
                          data-column-id="name"
                          class="gridjs-th gridjs-th-sort"
                          tabIndex="0"
                          style="minwidth: 44px; width: 188px"
                        >
                          <div class="gridjs-th-content">Title</div>
                        </th>
                        <th
                          data-column-id="name"
                          class="gridjs-th gridjs-th-sort"
                          tabIndex="0"
                          style="minwidth: 44px; width: 80px"
                        >
                          <div class="gridjs-th-content">Plan Type</div>
                        </th>
                        <th
                          data-column-id="email"
                          class="gridjs-th gridjs-th-sort"
                          tabIndex="0"
                          style="minwidth: 06px; width: 100px"
                        >
                          <div class="gridjs-th-content">Price</div>
                        </th>
                        <th
                          data-column-id="email"
                          class="gridjs-th gridjs-th-sort"
                          tabIndex="0"
                          style="minwidth: 06px; width: 100px"
                        >
                          <div class="gridjs-th-content">Term</div>
                        </th>
                        <th
                          data-column-id="email"
                          class="gridjs-th gridjs-th-sort"
                          tabIndex="0"
                          style="minwidth: 06px; width: 100px"
                        >
                          <div class="gridjs-th-content">Trial</div>
                        </th>
                        <th
                          data-column-id="date"
                          class="gridjs-th gridjs-th-sort"
                          tabIndex="0"
                          style="minwidth: 98px; width: 127px"
                        >
                          <div class="gridjs-th-content">No. of Day Notify </div>
                        </th>
                        <th
                          data-column-id="date"
                          class="gridjs-th gridjs-th-sort"
                          tabIndex="0"
                          style="minwidth: 98px; width: 50px"
                        >
                          <div class="gridjs-th-content">Status</div>
                        </th>
                        <th
                          data-column-id="date"
                          class="gridjs-th gridjs-th-sort"
                          tabIndex="0"
                          style="minwidth: 98px; width: 137px"
                        >
                          <div class="gridjs-th-content">Updated</div>
                        </th>
                        <th
                          data-column-id="product"
                          class="gridjs-th gridjs-th-sort"
                          tabIndex="0"
                          style="minwidth: 91px; width: 50px"
                        >
                          <div class="gridjs-th-content">Action</div>
                        </th>
                       
                      </tr>
                    </thead>
                    <tbody class="gridjs-tbody">
                      
                      <tr v-for="(item, i) in plan" :key="i" class="gridjs-tr">
                        
                        <!-- <td class="gridjs-td">
                          <span>
                            <h5 class="font-size-15">{{item.id}}</h5>
                          </span>
                        </td> -->
                        <td  class="gridjs-td">
                          {{item.title}}
                        </td>
                        <td  class="gridjs-td">
                          <span>
                            <span v-if="item.plan_type == 'free'" class="  badge badge-pill badge-soft-secondary font-size-12" >  {{item.plan_type.toUpperCase()}}</span>
                            
                            <span v-if="item.plan_type == 'premium'" class="badge badge-pill badge-soft-primary  font-size-12">  {{item.plan_type.toUpperCase()}}</span>
                          </span>
                        
                        </td>
                        <td  class="gridjs-td">
                          {{currencyFormat(parseFloat(item.price))}}
                        </td>
                        <td class="gridjs-td" style="text-transform:capitalize">
                          <span>
                            <span class="  badge badge-pill bg-success font-size-12" >{{item.term}}</span>
                          </span>
                        </td>
                        <td  class="gridjs-td">
                          {{item.trial}}
                        </td>
                        <td  class="gridjs-td">
                          {{item.day_notification}}
                        </td>
                        <td  class="gridjs-td">
                          <span>
                            <span v-if="item.status == 1" class="  badge badge-pill badge-soft-success font-size-12" >Active</span>
                            
                            <span v-if="item.status == 0" class="badge badge-pill badge-soft-danger  font-size-12">Inactive</span>
                          </span>
                          
                           
                        </td>
                        <td data-column-id="product" class="gridjs-td">{{formatDate(item.updated_at)}}</td>
                        <td data-column-id="action" class="gridjs-td">
                          <span>
                           <div class="dropdown">
                                <a href="#" class="dropdown-toggle card-drop" data-bs-toggle="dropdown" aria-expanded="false"><i class="mdi mdi-dots-vertical font-size-18"></i></a>
                                <ul class="dropdown-menu dropdown-menu-end" style="">
                                  <li><a href="javascript:void(0)" class="dropdown-item" @click="showModalEdit(item)"><i class="mdi mdi-pencil font-size-16 text-success me-1"></i> Edit</a></li>
                                  <li><a href="javascript:void(0)" class="dropdown-item" @click="deleteInclusion(item.id)"><i class="mdi mdi-trash-can font-size-16 text-danger me-1"></i> Delete</a></li></ul>
                                </div>
                          </span>
                        </td>
                        
                      </tr>
                    </tbody>
                  </table>
                </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <b-pagination
                          v-model="currentPage"
                          :total-rows="pagination.rows"
                          :per-page="pagination.perPage"
                          :limit="5"
                          @update:modelValue="changePage"
                ></b-pagination>
            </div>
    </div>

    <!-- end row -->
  <plan-create :modalShow="modalCreate" @modalClose ="hideModal"></plan-create>
 
  </Layout>
</template>
<script>
  import Layout from "../../../layouts/main";
  import planCreate from "./planCreate.vue";
  import { useStore } from 'vuex'
  import {ref,computed,reactive,onMounted,watch} from 'vue';
  import { useDebouncedRef } from "../../../../components/utility/debounce";
  import moment from 'moment'
  import Swal from 'sweetalert2'

  export default {
    components:{
      planCreate,
      Layout
    },
    setup() {
      const modalCreate = ref(false)
      const modalEdit = ref(false)
      const search = useDebouncedRef('',400)
      const edit = reactive({
        id:null,
        title:''
      })
      const currentPage = ref(1)

      const store = useStore()
      const showModalCreate = () =>{
        modalCreate.value = true;
      }
      const showModalEdit = (index) =>{
        Object.assign( edit,{id:index.id,title:index.title});
        console.log(edit)

        modalEdit.value = true;
      }
      function hideModal(){
        modalCreate.value = false;
        modalEdit.value = false;
      }
      function currencyFormat(amount){
          return amount.toLocaleString('en-US', {
            style: 'currency',
            currency:'usd',
          }); 
        }
      const success = computed(()=>{
          return store.getters['subscription/success']
      });
      const plan = computed(()=>{
          return store.getters['subscription/getPlan']
      });
      const pagination = computed(()=>{
          return store.getters['subscription/getPagination']
      });
      const  spinner =  computed(() =>{ return  store.getters['subscription/processingData']})

      const unSubscribeSuccess = () =>{
         store.commit('subscription/setSuccess',false)
      }
      function changePage(){
         store.dispatch('subscription/getPlan',{page:currentPage.value,search:''});
      }
      function formatDate(date) {
          return moment(date).format("MMMM Do YYYY, h:mm:ss a");
      }
       function deleteInclusion(id){
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this action!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes, delete it!"
        }).then(result => {
          if (result.value) {
            store.dispatch('subscription/deletePlan',id);
            store.dispatch('subscription/getPlan',{page:currentPage.value,search:search.value});
          }
        });
      }
      onMounted(() => {
           store.dispatch('subscription/getPlan',{page:1,search:''});
      })
      watch(search , newSearch=>{
          store.dispatch('subscription/getPlan',{page:1,search:newSearch});
      })
      return {
        showModalCreate,
        showModalEdit,
        formatDate,
        edit,
        deleteInclusion,
        search,
        changePage,
        unSubscribeSuccess,
        success,
        modalCreate,
        modalEdit,
        hideModal,
        plan,
        spinner,
        pagination,currentPage,
        currencyFormat
      };
    }
  };
  </script>